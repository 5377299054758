import React, { useState } from 'react';
import Layout from '../../components/layout'
import { getStore } from '../../redux/store';
import { register } from '../../redux/actions';
import { useDispatch } from 'react-redux';

const RegisterPage = () => {
  const dispatch = useDispatch();
  const [form, setForm] = useState({firstName: '', lastName: '', email: '', password: ''});
  const {firstName, lastName, email, password} = form;
  const setField = field => event => setForm({...form, [field]: event.target.value});

  const onSubmit = () => {
    dispatch(register({
      firstName,
      lastName,
      email,
      password,
    }));
  }

  return (
    <Layout>
      <input type="text" value={firstName} onChange={setField("firstName")} placeholder="Jeanne"/>
      <input type="text" value={lastName} onChange={setField("lastName")} placeholder="Dubois"/>
      <input type="email" value={email} onChange={setField("email")} placeholder="example@example.com"/>
      <input type="password" value={password} onChange={setField("password")} placeholder="********"/>
      <button onClick={onSubmit}>Create</button>
    </Layout>);
}

export default RegisterPage;